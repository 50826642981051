<template>
  <div class="overview-page">
    <MainNav />
     
    <section class="">

    </section>

    <rightNav />
    <Footer />
  </div>
</template>

<script>
import MainNav from "../components/MainNav/index";
import Footer from "../components/Footer.vue";
import rightNav from "../components/RightNav/RightNav";


export default {
  name:"Overview",
  data() {
    return {
    };
  },
  components: {
    MainNav,
    Footer,
    rightNav,
  },
  mounted(){
  },
  methods:{
    
  }
};
</script>

<style scoped lang="less">

</style>